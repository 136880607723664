<template>
  <q-layout view="hhr Lpr ffr">
    <q-header style="background: none !important">
      <AppHeader @open="drawer = true" />
    </q-header>
    <q-drawer v-model="drawer" elevated side="right" overlay>
      <div class="w-100 h-100 outer-sidebar" @click.self="drawer = false">
        <div class="sidebar">
          <div class="flex justify-end">
            <q-icon
              name="close"
              class="q-ma-md clickable"
              color="white"
              size="2em"
              @click="drawer = false"
            />
          </div>
          <q-list separator>
            <q-expansion-item v-model="menu" expand-separator>
              <template v-slot:header>
                <div class="flex items-center w-100">
                  <span class="text-white text-h7">Условия и политика</span>
                </div>
              </template>
              <q-list class="q-pl-md">
                <q-item class="text-white clickable text-h7"
                  >Политика конфиденциальности
                </q-item>
                <q-item class="text-white clickable text-h7"
                  >Условия использования
                </q-item>
                <q-item class="text-white clickable text-h7"
                  >Политика возврата
                </q-item>
                <q-item class="text-white clickable text-h7"
                  >Условия подписки
                </q-item>
                <q-item class="text-white clickable text-h7"
                  >Политика в отношении файлов cookie
                </q-item>
                <q-item class="text-white clickable text-h7"
                  >Не продавать мою личную информацию
                </q-item>
              </q-list>
            </q-expansion-item>
            <router-link class="w-100" to="/sign-in" @click="drawer = false">
              <q-item class="text-white clickable text-h7">
                Мой аккаунт
              </q-item>
            </router-link>
            <router-link
              class="w-100"
              to="/profile"
              @click="drawer = false"
              v-if="user"
            >
              <q-item class="text-white clickable text-h7"> Профиль</q-item>
            </router-link>
            <router-link
              class="w-100"
              to="/cabinet"
              @click="drawer = false"
              v-if="user"
            >
              <q-item class="text-white clickable text-h7"> Кабинет</q-item>
            </router-link>
            <router-link
              class="w-100"
              to="/workouts"
              @click="drawer = false"
              v-if="user?.role == 'admin'"
            >
              <q-item class="text-white clickable text-h7">
                Список программ тренировок
              </q-item>
            </router-link>
            <router-link
              class="w-100"
              to="/exercises"
              @click="drawer = false"
              v-if="user?.role == 'admin'"
            >
              <q-item class="text-white clickable text-h7">
                Список упражнений
              </q-item>
            </router-link>
            <a href="mailto:support@teloklass.ru" @click="drawer = false">
              <q-item class="text-white clickable text-h7">Поддержка</q-item>
            </a>
            <router-link class="w-100" to="/reviews" @click="drawer = false">
              <q-item class="text-white clickable text-h7"> Отзывы</q-item>
            </router-link>
            <router-link class="w-100" to="/about-us" @click="drawer = false">
              <q-item class="text-white clickable text-h7"> О нас</q-item>
            </router-link>
            <span class="w-100" @click="(drawer = false), logout()" v-if="user">
              <q-item class="text-white clickable text-h7"> Выйти</q-item>
            </span>
          </q-list>
        </div>
      </div>
    </q-drawer>
    <q-page-container class="w-100">
      <div
        v-if="isLoading"
        class="flex justify-center items-center h-500 column loader"
      >
        <q-spinner-hourglass color="white" size="7em" />
        <strong>Загрузка...</strong>
      </div>
      <router-view />
    </q-page-container>
    <q-footer style="background: #1e1e1e" class="q-py-lg">
      <AppFooter />
    </q-footer>
  </q-layout>
</template>
<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
  data() {
    return {
      drawer: false,
      menu: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    in_out() {
      return this.$store.state.in_out;
    },
  },
  methods: {
    logout() {
      this.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      if (this.$route.meta.auth) this.$router.replace("/");
    },
  },
  watch: {
    drawer() {
      this.menu = false;
    },
    in_out(v) {
      if (v) {
        this.user = JSON.parse(localStorage.getItem("user"));
        this.$store.state.in_out = false;
      }
    },
  },
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
<style>
.q-layout {
  display: flex;
}

.q-drawer {
  top: 0 !important;
  width: 100% !important;
  background: #0005 !important;
}

.q-page-container {
  padding-top: 0px !important;
}
</style>
<style scoped>
.sidebar {
  margin-left: auto;
  background: #2a2a2a !important;
  width: 300px;
  height: 100vh;
}

.sidebar a {
  text-decoration: none;
}

.outer-sidebar {
  position: absolute;
  left: 0;
  right: 0;
}

.loader {
  background: #2a2a2a;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2500;
}
</style>
