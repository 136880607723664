import "quasar/dist/quasar.css";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import Notify from 'quasar/src/plugins/Notify.js';;
import ru from "quasar/lang/ru";

export default {
  lang: ru,
  config: {
    dark: true,
  },
  plugins: {
    Notify,
  },
};
