<template>
  <AppLayout />
</template>

<script>
import AppLayout from "./components/AppLayout.vue";

export default {
  name: "LayoutDefault",
  components: {
    AppLayout,
  },
};
</script>
<style src="./style.css" />
<style src="./mini-boot.css" />
