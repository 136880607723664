<template>
  <div class="flex justify-between items-center q-pa-sm">
    <router-link to="/">
      <img src="/img/logo.svg" class="logo" />
    </router-link>
    <q-icon
      name="menu"
      size="2em"
      class="clickable"
      @click.prevent="$emit('open')"
    />
  </div>
</template>
<style scoped>
.logo {
  height: 32px;
  width: auto;
}
</style>
