import { createStore } from "vuex";

export default createStore({
  state: {
    isLoading: false,
    in_out: false,
    age: null,
    train_data: {
      gender: null,
      body_goal: null,
      body_target: null,
      body_type: null,
      fatness: null,
      problems: null,
      height: null,
      curWeight: null,
      targetWeight: null,
      fitness: null,
      prefers: null,
      sports: null,
      issues: null,
      additional_goals: null,
      push_ups: null,
      pull_ups: null,
      place: null,
      equipment: null,
      workout_frequency: null,
      workout_duration: null,
      water: null,
      time: null,
      siteChoose: true,
      products: null,
      challenge: null,
      name: null,
      birthday: null,
      email: null,
    },
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
