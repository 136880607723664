import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("../views/SignInView.vue"),
    meta: { auth: false },
  },
  {
    path: "/recovery",
    name: "recovery",
    component: () => import("../views/RecoveryView.vue"),
    meta: { auth: false },
  },
  {
    path: "/send-password",
    name: "send-password",
    component: () => import("../views/SendPasswordView.vue"),
    meta: { auth: false },
  },
  {
    path: "/password-info",
    name: "password-info",
    component: () => import("../views/PasswordInfoView.vue"),
    meta: { auth: false },
  },
  {
    path: "/reviews",
    name: "reviews",
    component: () => import("../views/ReviewsView.vue"),
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("../views/AboutUsView.vue"),
  },
  {
    path: "/step-gender",
    name: "step-gender",
    component: () => import("../views/gain-info/StepGenderView.vue"),
  },
  {
    path: "/step-goal",
    name: "step-goal",
    component: () => import("../views/gain-info/StepGoalView.vue"),
  },
  {
    path: "/step-body-goal",
    name: "step-body-goal",
    component: () => import("../views/gain-info/StepGoalBodyView.vue"),
  },
  {
    path: "/step-target-body",
    name: "step-target-body",
    component: () => import("../views/gain-info/StepTargetBodyView.vue"),
  },
  {
    path: "/step-body-type",
    name: "step-body-type",
    component: () => import("../views/gain-info/StepBodyTypeView.vue"),
  },
  {
    path: "/step-level-of-fat",
    name: "step-level-of-fat",
    component: () => import("../views/gain-info/StepLevelOfFatView.vue"),
  },
  {
    path: "/step-problem-areas",
    name: "step-problem-areas",
    component: () => import("../views/gain-info/StepProblemAreasView.vue"),
  },
  {
    path: "/step-measures",
    name: "step-measures",
    component: () => import("../views/gain-info/StepMeasuresView.vue"),
  },
  {
    path: "/step-target-weight",
    name: "step-target-weight",
    component: () => import("../views/gain-info/StepTargetWeightView.vue"),
  },
  {
    path: "/step-level-of-fitness",
    name: "step-level-of-fitness",
    component: () => import("../views/gain-info/StepLevelOfFitnessView.vue"),
  },
  {
    path: "/step-love-or-hate",
    name: "step-love-or-hate",
    component: () => import("../views/gain-info/StepLoveOrHateView.vue"),
  },
  {
    path: "/step-workouts",
    name: "step-workouts",
    component: () => import("../views/gain-info/StepWorkoutsView.vue"),
  },
  {
    path: "/step-workout-issues",
    name: "step-workout-issues",
    component: () => import("../views/gain-info/StepWorkoutIssuesView.vue"),
  },
  {
    path: "/step-give-up",
    name: "step-give-up",
    component: () => import("../views/gain-info/StepGiveUpView.vue"),
  },
  {
    path: "/step-additional-goals",
    name: "step-additional-goals",
    component: () => import("../views/gain-info/StepAdditionalGoalsView.vue"),
  },
  {
    path: "/step-attention",
    name: "step-attention",
    component: () => import("../views/gain-info/StepAttentionView.vue"),
  },
  {
    path: "/step-push-ups",
    name: "step-push-ups",
    component: () => import("../views/gain-info/StepPushUpsView.vue"),
  },
  {
    path: "/step-pull-ups",
    name: "step-pull-ups",
    component: () => import("../views/gain-info/StepPullUpsView.vue"),
  },
  {
    path: "/step-workout-place",
    name: "step-workout-place",
    component: () => import("../views/gain-info/StepWorkoutPlaceView.vue"),
  },
  {
    path: "/step-equipment",
    name: "step-equipment",
    component: () => import("../views/gain-info/StepEquipmentView.vue"),
  },
  {
    path: "/step-workout-frequency",
    name: "step-workout-frequency",
    component: () => import("../views/gain-info/StepWorkoutFrequencyView.vue"),
  },
  {
    path: "/step-workout-duration",
    name: "step-workout-duration",
    component: () => import("../views/gain-info/StepWorkoutDurationView.vue"),
  },
  {
    path: "/step-level-of-hormones",
    name: "step-level-of-hormones",
    component: () => import("../views/gain-info/StepLevelOfHormonesView.vue"),
  },
  {
    path: "/step-water",
    name: "step-water",
    component: () => import("../views/gain-info/StepWaterView.vue"),
  },
  {
    path: "/step-time",
    name: "step-time",
    component: () => import("../views/gain-info/StepTimeView.vue"),
  },
  {
    path: "/step-products",
    name: "step-products",
    component: () => import("../views/gain-info/StepProductsView.vue"),
  },
  {
    path: "/step-challenge",
    name: "step-challenge",
    component: () => import("../views/gain-info/StepChallengeView.vue"),
  },
  {
    path: "/step-name",
    name: "step-name",
    component: () => import("../views/gain-info/StepNameView.vue"),
  },
  {
    path: "/step-date-of-birth",
    name: "step-date-of-birth",
    component: () => import("../views/gain-info/StepBirthdayView.vue"),
  },
  {
    path: "/step-email",
    name: "step-email",
    component: () => import("../views/gain-info/StepEmailView.vue"),
  },
  {
    path: "/my-info",
    name: "my-info",
    component: () => import("../views/profile/MyInfoView.vue"),
    meta: { auth: true },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/profile/ProfileView.vue"),
    meta: { auth: true },
  },
  {
    path: "/cabinet",
    name: "cabinet",
    component: () => import("../views/profile/CabinetView.vue"),
    meta: { auth: true },
  },
  {
    path: "/workouts",
    name: "workouts",
    component: () => import("../views/profile/WorkoutsView.vue"),
    meta: { auth: true, admin: true },
  },
  {
    path: "/add-workout",
    name: "add-workout",
    component: () => import("../views/profile/AddWorkoutView.vue"),
    meta: { auth: true, admin: true },
  },
  {
    path: "/edit-workout/:id",
    name: "edit-workout",
    component: () => import("../views/profile/EditWorkoutView.vue"),
    meta: { auth: true, admin: true },
  },
  {
    path: "/workout/:id",
    name: "workout",
    component: () => import("../views/profile/WorkoutView.vue"),
    meta: { auth: true },
  },
  {
    path: "/exercises",
    name: "exercises",
    component: () => import("../views/profile/ExercisesView.vue"),
    meta: { auth: true, admin: true },
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: () => import("../views/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  linkExactActiveClass: "active-link",
  scrollBehavior() {
    return { top: 0 };
  },
});
router.beforeEach((to, _, next) => {
  let user = JSON.parse(localStorage.getItem("user"));
  if (to.meta.auth && user != null) {
    if (to.meta.admin) {
      if (user.role === "user" || (user.role === "manager" && to.meta.users))
        next("/cabinet");
      else next();
    } else next();
  } else if (!to.meta.auth && !(user != null)) next();
  else if (to.meta.auth && !(user != null)) next("/");
  else if (to.meta.auth === false && user) next("my-info");
  next();
});
export default router;
