<template>
  <div class="container row justify-center footer">
    <router-link to="">Условия использования</router-link>
    <router-link to="">Политика конфиденциальности</router-link>
    <router-link to="">Поддержка</router-link>
  </div>
</template>
<style>
.footer a {
  text-decoration: underline;
  color: #b3b3b3;
  margin: 5px 15px;
  font-size: 18px;
}

.footer a:hover {
  text-decoration: none;
}
</style>
